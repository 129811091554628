import axios from 'axios';
import { AUTH_TOKEN, AUTH_USER } from 'constants/AuthConstant';
import { TOKEN_PAYLOAD_KEY } from 'auth/FetchInterceptor';

export { API_BASE_URL } from 'constants/ApiConstant';

export const LEVITA_URL = 'https://api.levita.app';

export const AUTH_HEADER = {
  [TOKEN_PAYLOAD_KEY]: localStorage.getItem(AUTH_TOKEN),
};

export const getToken = () => {
  const user = JSON.stringify(localStorage.getItem(AUTH_USER));
  return {
    [TOKEN_PAYLOAD_KEY]: user?.token || localStorage.getItem(AUTH_TOKEN),
  };
};

export default class AxiosRequest {
  constructor(config) {
    this.signal = undefined;
    this.controller = undefined;
    this.instance = axios.create(config);
  }

  getInstance() {
    return {
      signal: this.abort(),
      instance: this.instance,
    };
  }

  getSignal() {
    return this.signal ? { signal: this.signal } : {};
  }

  setCancelToken() {
    const controller = new AbortController();
    const { signal } = controller;
    this.controller = controller;
    this.signal = signal;
  }

  cleanCancelToken() {
    this.controller = undefined;
    this.signal = undefined;
  }

  cancel() {
    if (this?.controller && this?.controller?.abort) {
      this.controller.abort();
    }
  }

  abort() {
    this.cancel();
    this.cleanCancelToken();
    this.setCancelToken();
    return this.getSignal();
  }
}
